import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { useSelector, shallowEqual, connect, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { Field, Formik, useFormik } from "formik";
import * as Yup from "yup";
import {
  DatePickerField,
  Input,
  ModalProgressBar,
  Select,
} from "../../../_metronic/_partials/controls";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import * as auth from "../Auth";
import { Form, Row } from "react-bootstrap";
import { sendRequestDotNet } from "../../../_utils/Axios";
import moment from "moment";
import {
  getProfile,
  updateProfile,
  uploadProfilePicture,
} from "./_redux/Profile/profileActions";
import { KTCookie } from "../../../_metronic/_assets/js/components/cookie";
import { FormattedMessage, useIntl } from "react-intl";
import { TextField } from "@material-ui/core";

const useStyles4 = makeStyles({
  grid: {
    width: "60%",
  },
});

const useStyles = makeStyles({
  grid: {
    width: "60%",
  },
});

function PersonaInformation(props) {
  // Fields
  const [loading, setloading] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [pic, setPic] = useState("");
  const intl = useIntl();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user, shallowEqual);
  const userProfile = useSelector(
    (state) => state.profileSlice.userProfile,
    shallowEqual
  );
  console.log(userProfile);

  const [locations, setLocations] = useState([]);
  const [genders, setGenders] = useState([]);
  const [simLanguages, setSimLanguages] = useState([]);

  // const [selectedDate, setSelectedDate] = useState(userProfile && userProfile.dateOfBirth);

  const classes = useStyles();
  const classes4 = useStyles4();

  // function handleDateChange(date) {
  //   setSelectedDate(date);
  // }

  useEffect(() => {
    // formik.setFieldValue("idLocation",user.userProfile.idLocation)
    if (
      userProfile &&
      userProfile.profilePicture &&
      userProfile.profilePicture != null
    ) {
      setPic(userProfile.profilePicture);
    }
  }, [userProfile]);
  // Methods
  const saveUser = (values, setStatus, setSubmitting) => {
    setloading(true);
    console.log(values);
    // const updatedUser = Object.assign(user, values);
    // user for update preparation
    // dispatch(props.setUser(updatedUser));
    // setTimeout(() => {
    //   setloading(false);
    //   setSubmitting(false);
    //   // Do request to your server for user update, we just imitate user update there, For example:
    //   // update(updatedUser)
    //   //  .then(()) => {
    //   //    setloading(false);
    //   //  })
    //   //  .catch((error) => {
    //   //    setloading(false);
    //   //    setSubmitting(false);
    //   //    setStatus(error);
    //   // });
    // }, 1000);
  };
  // UI Helpers
  const initialValues = userProfile && {
    profilePicture:
      userProfile.profilePicture &&
      userProfile.profilePicture != null &&
      userProfile.profilePicture,

    firstName: userProfile.firstName,
    fathername: userProfile.fathername,
    lastName: userProfile.lastName,
    msisdn: userProfile.msisdn,
    // dateOfBirth: userProfile.dateOfBirth,
    email: userProfile.email,
    genderId: userProfile.genderId,
    idLocation: userProfile.idLocation,
    simIdLanguage: userProfile.simIdLanguage,
  };
  const Schema = Yup.object().shape({
    firstName: Yup.string()
      .trim()
      .max(
        30,
        intl.formatMessage({
          id: "AUTH.VALIDATION.MAX_LENGTH_FIELD_30",
        })
      )
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    lastName: Yup.string()
      .trim()
      .max(
        30,
        intl.formatMessage({
          id: "AUTH.VALIDATION.MAX_LENGTH_FIELD_30",
        })
      )
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    // fatherName: Yup.string().required("Last name is required"),
    email: Yup.string()
      .max(
        60,
        intl.formatMessage({
          id: "AUTH.VALIDATION.MAX_LENGTH_FIELD_60",
        })
      )
      .email(
        intl.formatMessage({
          id: "AUTH.VALIDATION.WRONG_EMAIL_FORMAT",
        })
      ),
      // .required(
      //   intl.formatMessage({
      //     id: "AUTH.VALIDATION.REQUIRED_FIELD",
      //   })
      // )
    dateOfBirth: Yup.string().required(
      intl.formatMessage({
        id: "AUTH.VALIDATION.REQUIRED_FIELD",
      })
    ),
    genderId: Yup.string().required(
      intl.formatMessage({
        id: "AUTH.VALIDATION.REQUIRED_FIELD",
      })
    ),
    // countryOfResidenceId: Yup.string().required("Country Of Residence is required"),
    simIdLanguage: Yup.string().required(
      intl.formatMessage({
        id: "AUTH.VALIDATION.REQUIRED_FIELD",
      })
    ),
    // userIdLanguage: Yup.string().required("Language is required"),
    idLocation: Yup.string().required(
      intl.formatMessage({
        id: "AUTH.VALIDATION.REQUIRED_FIELD",
      })
    ),
  });
  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };
  const formik = useFormik({
    initialValues: { initialValues },
    validationSchema: Schema,
    enableReinitialize: true,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      saveUser(values, setStatus, setSubmitting);
    },
    // onReset: (values, { resetForm }) => {
    //   resetForm();
    // },
  });
  const getUserPic = () => {
    if (!pic) {
      return "none";
    }

    return `url(${pic})`;
  };
  const removePic = () => {
    setPic("");
  };
  const ref = useRef();

  useEffect(() => {
    sendRequestDotNet("Profile/LoadData", {}, {}).then((response) => {
      setLocations(response.data.locations);
      setGenders(response.data.genders);
      setSimLanguages(response.data.simLanguages);
    });
  }, []);

  const saveProfile = (profile, setStatus, setSubmitting) => {
    setUploading(true);
    dispatch(updateProfile(profile)).then((response) => {
      // console.log(response)
      setUploading(false);
      // setStatus(response.message);
      setSubmitting(false);
      // if(response==0)
      // {
      dispatch(getProfile());
      // }
    });
  };
  const onChangePic = () => {
    console.log(ref);
    var file = ref.current.files[0];
    var reader = new FileReader();
    var url = reader.readAsDataURL(file);

    reader.onloadend = function(e) {
      setPic(reader.result);
    }.bind(this);
    let formData = new FormData();
    formData.append("file", file);
    formData.append("uid", KTCookie.getCookie("idUserProfile"));
    // console.log(formData)
    dispatch(uploadProfilePicture(formData)).then((response) => {
      // console.log(response)
      // setStatus(response.message);
      // setSubmitting(false)
      // if(response==0)
      // {
      dispatch(getProfile());
      // }
    });
    // console.log(url) // Would see a path?
    // TODO: concat files\
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={Schema}
      onSubmit={(values, { setStatus, setSubmitting }) => {
        // console.log(values)
        saveProfile(values, setStatus, setSubmitting);
      }}
    >
      {({
        handleSubmit,
        setFieldValue,
        values,
        errors,
        isSubmitting,
        touched,
        isValid,
        setSubmitting,
        status,
        getFieldProps,
      }) => (
        <>
          {/* {isSubmitting && (
            <div className="overlay-layer bg-transparent">
              <div className="spinner spinner-lg spinner-success" />
            </div>
          )} */}
          {status && (
            <div className="mb-10 alert alert-custom alert-light-success alert-dismissible">
              <div className="alert-text font-weight-bold">{status}</div>
            </div>
          )}
          <Form className="form form-label-right card card-custom card-stretch">
            <div className="card-header py-3">
              <div className="card-title">
                <h1 className="card-label font-weight-bolder text-dark">
                  <FormattedMessage id="AUTH.EDIT_PROF"></FormattedMessage>
                </h1>
                {/* <span className="text-muted font-weight-bold font-size-sm mt-1">
            Update your personal informaiton
          </span> */}
              </div>
              <div className="card-toolbar">
                <button
                  onClick={() => {
                    handleSubmit();
                  }}
                  className="btn btn-warning mr-2"
                  disabled={isSubmitting || (touched && !isValid)}
                >
                  <b>
                    <FormattedMessage id="AUTH.SAVE"></FormattedMessage>
                  </b>
                  {isSubmitting && (
                    <span className="mx-3 spinner spinner-white"></span>
                  )}
                </button>
                {/* <Link
            to="/user-profile/profile-overview"
            className="btn btn-secondary"
          >
            Cancel
          </Link> */}
              </div>
            </div>
            {/* <pre>{JSON.stringify(errors, null, 2)}</pre>
            <pre>{JSON.stringify(values, null, 2)}</pre> */}

            <div className="form">
              <div className="card-body">
                <div className="form-group row">
                  <label className="col-xl-4 col-lg-4 col-form-label">
                    <FormattedMessage id="AUTH.PROF_PIC"></FormattedMessage>
                  </label>
                  <div className="col-lg-8 col-xl-8">
                    <div
                      className="image-input image-input-outline"
                      id="kt_profile_avatar"
                      style={{
                        backgroundImage: `url(${toAbsoluteUrl(
                          "/media/users/blank.png"
                        )}`,
                      }}
                    >
                      <div
                        className="image-input-wrapper"
                        style={{ backgroundImage: `${getUserPic()}` }}
                      />
                      <label
                        className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                        data-action="change"
                        data-toggle="tooltip"
                        title=""
                        data-original-title="Change avatar"
                      >
                        {uploading ? (
                          <div className="spinner spinner-sm spinner-warning mr-4" />
                        ) : (
                          <i className="fa fa-pen icon-sm text-muted"></i>
                        )}

                        <form>
                          <input
                            disabled={uploading}
                            ref={ref}
                            type="file"
                            name="user[image]"
                            multiple="true"
                            accept="image/png, image/jpg, image/jpeg"
                            onChange={onChangePic}
                          />
                        </form>
                        {/* <input
                    type="file"
                    name="profilePicture"
                    value={pic}
                    onChange={(e)=>console.log(e.target.value)}
                    accept=".png, .jpg, .jpeg"
                    {...getFieldProps("profilePicture")}
                  /> */}
                        <input type="hidden" name="profile_avatar_remove" />
                      </label>
                      <span
                        className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                        data-action="cancel"
                        data-toggle="tooltip"
                        title=""
                        data-original-title="Cancel avatar"
                      >
                        <i className="ki ki-bold-close icon-xs text-muted"></i>
                      </span>
                      <span
                        onClick={removePic}
                        className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                        data-action="remove"
                        data-toggle="tooltip"
                        title=""
                        data-original-title="Remove avatar"
                      >
                        <i className="ki ki-bold-close icon-xs text-muted"></i>
                      </span>
                    </div>
                    <span className="form-text text-muted">
                      <FormattedMessage id="AUTH.ALLOWED_FILE"></FormattedMessage>
                    </span>
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-lg-4 text-bold col-form-label text-lg-left">
                    <FormattedMessage id="AUTH.FULL_NAME"></FormattedMessage>{" "}
                    <span className="ast">*</span>
                  </label>
                  <div className="col-lg-4 col-xl-4">
                    <Field
                      name="firstName"
                      component={Input}
                      placeholder={intl.formatMessage({
                        id: "AUTH.FIRST_NAME",
                      })}
                      label={intl.formatMessage({
                        id: "AUTH.FIRST_NAME",
                      })}
                    />
                  </div>
                  <div className="col-lg-4 col-xl-4">
                    <Field
                      name="lastName"
                      component={Input}
                      placeholder={intl.formatMessage({
                        id: "AUTH.LAST_NAME",
                      })}
                      label={intl.formatMessage({
                        id: "AUTH.LAST_NAME",
                      })}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-lg-4 text-bold col-form-label text-lg-left">
                    <FormattedMessage id="AUTH.EMAIL"></FormattedMessage>{" "}
                    <span className="ast">*</span>
                  </label>
                  <div className="col-lg-8 col-xl-8">
                    <Field
                      name="email"
                      type="email"
                      component={Input}
                      placeholder={intl.formatMessage({
                        id: "AUTH.EMAIL",
                      })}
                      label={intl.formatMessage({
                        id: "AUTH.EMAIL",
                      })}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-lg-4 text-bold col-form-label text-lg-left">
                    <FormattedMessage id="AUTH.MOBILE"></FormattedMessage>{" "}
                    {/* <span className="ast">*</span> */}
                  </label>
                  <div className="col-lg-8 col-xl-8">
                    <Field
                    disabled
                      name="msisdn"
                      component={Input}
                      placeholder={intl.formatMessage({
                        id: "AUTH.MOBILE",
                      })}
                      label={intl.formatMessage({
                        id: "AUTH.MOBILE",
                      })}
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <label className="col-xl-4 col-lg-4 col-form-label">
                    <FormattedMessage id="AUTH.DOB"></FormattedMessage>
                  </label>
                  <div className="col-lg-8 col-xl-8">
                    {/* <Field name="dateOfBirth">
            {({ field, form }) => (
              <TextField
              id="dateOfBirth"
              type="date"
              
              // selected={moment(field.value).format("yyyy-MM-dd")}
              value={moment(field.value).format("yyyy-MM-dd")}
              onChange={(date) => form.setFieldValue(field.name, date)}
              className={classes.textField}
              InputLabelProps={{
                shrink: true,
              }}
              {...field}
            />
            )}
          </Field> */}

                    <DatePickerField
                      // format=""
                      
                      // maxDate={moment().toDate()}
                      name="dateOfBirth"
                      // label="Date of Birth"
                    />
                    {/* <TextField
                    onChange={(e)=>{
                      setSelectedDate(e.target.value)
                      setFieldValue('dateOfBirth',e.target.value)}
                    }
                    id="dateOfBirth"
                    // label="Date Of Birth"
                    type="date"
                    name="dateOfBirth"
                    value={selectedDate}
                    // defaultValue="2017-05-24"
                    className={classes.textField}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  /> */}
                    {formik.touched.dateOfBirth && formik.errors.dateOfBirth ? (
                      <div className="invalid-feedback">
                        {formik.errors.dateOfBirth}
                      </div>
                    ) : null}
                  </div>
                </div>

                <div className="form-group row">
                  <label className="col-xl-4 col-lg-4 col-form-label">
                    <FormattedMessage id="AUTH.GENDER"></FormattedMessage>
                  </label>
                  <div
                    style={{ alignSelf: "center" }}
                    className="col-lg-8 col-xl-8"
                  >
                    <Row className="px-5">
                      {genders &&
                        genders.map((x) => (
                          <Form.Check
                            defaultChecked={
                              initialValues &&
                              initialValues.genderId == x.idParam
                            }
                            onChange={() =>
                              setFieldValue("genderId", x.idParam)
                            }
                            value={x.value}
                            className="px-5"
                            type="radio"
                            label={x.value}
                            name="formHorizontalRadios"
                            id={x.value}
                          />
                        ))}
                    </Row>
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-lg-4 text-bold col-form-label text-lg-left">
                    <FormattedMessage id="AUTH.LOCATION"></FormattedMessage>{" "}
                    <span className="ast">*</span>
                  </label>
                  <div className="col-lg-8 col-xl-8">
                    <Select
                      name="idLocation"
                      label={intl.formatMessage({
                        id: "AUTH.LOCATION",
                      })}
                      // value={user.userProfile.simIdLanguage}
                      // onChange={(e) => {

                      // }}
                    >
                      <option>
                        {intl.formatMessage({
                          id: "AUTH.SELECT_LOCATION",
                        })}
                      </option>
                      {locations &&
                        locations.map((x) => (
                          <option value={x.idParam}>{x.value}</option>
                        ))}
                    </Select>
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-lg-4 text-bold col-form-label text-lg-left">
                    <FormattedMessage id="AUTH.SIM_LANG"></FormattedMessage>{" "}
                    <span className="ast">*</span>
                  </label>
                  <div className="col-lg-8 col-xl-8">
                    <Select
                      name="simIdLanguage"
                      label=""
                      // value={user.userProfile.simIdLanguage}
                      // onChange={(e) => {

                      // }}
                    >
                      <option>
                        {intl.formatMessage({
                          id: "AUTH.SELECT_SIM_LANG",
                        })}
                      </option>
                      {simLanguages &&
                        simLanguages.map((x) => (
                          <option value={x.idParam}>{x.value}</option>
                        ))}
                    </Select>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        </>
      )}
    </Formik>
  );
}

export default connect(null, auth.actions)(PersonaInformation);
