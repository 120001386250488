/* eslint-disable react/jsx-no-undef */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, {useMemo, useEffect} from "react";
import objectPath from "object-path";
import ApexCharts from "apexcharts";
import {Dropdown, Image} from "react-bootstrap";
import {useHtmlClassService} from "../../../layout";
import {DropdownMenu4, DropdownCustomToggler} from "../../dropdowns";
import {KTUtil} from "../../../_assets/js/components/util";
import Silver from "./silver.png"

export function MixedWidget14({ className,loyaltyDetails }) {
function getChartOptions(layoutProps, height) {

  var options = {
    series: [parseInt((loyaltyDetails.nextLevelDetails.totalPoint/loyaltyDetails.nextLevelDetails.pendingAmount)*100)],
    chart: {
    height: 180,
    type: 'radialBar',
    // offsetY: -10
  },
  plotOptions: {
    radialBar: {
      hollow: {
        margin: 10,
        size: '70%',
        image: loyaltyDetails.loyaltyLevel==1?"/media/korek/gold.png":(loyaltyDetails.loyaltyLevel==2?"/media/korek/silver.png":"/media/korek/bronze.png"),
        imageWidth: 64,
        // backgroung:"transparent",
        imageHeight: 64,
        imageClipped: false
      },
      startAngle: -135,
      endAngle: 135,
      dataLabels: {
        name: {
          fontSize: '16px',
          fontWeight:"bold",
          color: "white",
          offsetY: 60
        },
        value: {
          show: true,
          offsetY: 76,
          fontSize: '22px',
          color: undefined,
          formatter: function (val) {
            // return val + "%";
            // return         <Image className="level-icon" style={{ float: "center" }} src="/media/korek/silver.png"></Image>
          }
        }
      }
    }
  },
  fill: {
  colors: ['#fff', '#B32824']
    // type: 'gradient',
    // gradient: {
    //     shade: 'light',
    //     shadeIntensity: 0.15,
    //     inverseColors: false,
    //     opacityFrom: 1,
    //     opacityTo: 1,
    //     stops: [0, 50, 65, 91]
    // },
  },
  labels: [loyaltyDetails.loyaltyLevel==1?"Gold":(loyaltyDetails.loyaltyLevel==2?"Silver":"Bronze")],
  stroke: {
    lineCap: 'round'
  },
  };
  return options;
}

  const uiService = useHtmlClassService();

  const layoutProps = useMemo(() => {
    return {
      colorsGrayGray100: objectPath.get(uiService.config, "js.colors.gray.gray100"),
      colorsGrayGray700: objectPath.get(uiService.config, "js.colors.gray.gray700"),
      colorsThemeBaseSuccess: objectPath.get(
          uiService.config,
          "js.colors.theme.base.success"
      ),
      colorsThemeLightSuccess: objectPath.get(
          uiService.config,
          "js.colors.theme.light.success"
      ),
      fontFamily: objectPath.get(uiService.config, "js.fontFamily")
    };
  }, [uiService]);

  useEffect(() => {
    const element = document.getElementById("kt_mixed_widget_14_chart");
    if (!element) {
      return;
    }

    const height = parseInt(KTUtil.css(element, 'height'));
    const options = getChartOptions(layoutProps, height);

    const chart = new ApexCharts(element, options);
    chart.render();
    return function cleanUp() {
      chart.destroy();
    };
  }, [layoutProps]);

  return (
        <div className="flex-grow-1">
          <div id="kt_mixed_widget_14_chart" style={{height: "auto"}}></div>
        </div>
  );
}


