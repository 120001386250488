import React, { useEffect, useState } from "react";
import {
  MixedWidget1,
  MixedWidget14,
  ListsWidget9,
  StatsWidget11,
  StatsWidget12,
  ListsWidget1,
  AdvanceTablesWidget2,
  AdvanceTablesWidget4,
  ListsWidget3,
  ListsWidget4,
  ListsWidget8,
} from "../widgets";
import { sendRequestDotNet } from "../../../_utils/Axios";
import "../../../_metronic/_assets/sass/pages/dashboard/dashboard.scss";
import { Col, Image, Row } from "react-bootstrap";
import { KTCookie } from "../../_assets/js/components/cookie";
export function Demo1Dashboard() {

  const [data,setData] =useState();
  
  useEffect(() => {    
    sendRequestDotNet("Main/Dashboard",{"isWithAccountItem":true},{}).then((response)=>{
      setData(response.data)
      KTCookie.setCookie("accountType",response.data.profile.accountType)
  })},[]);
  return (
    <div className="dashboard">

    {/* <Row className="mx-auto pt-5" style={{ width: "100%" }}>
            {data &&
              data.accountItems.map((x,i)=>x.idType!=5 && (
                
            <Col>
              <Row className="account-info">
                <Col xl={3}>
                  <Image className="" src={"/media/korek/account-"+x.idType+".png"}></Image>
                </Col>{" "}
                <Col xl={9}>
                  <span className="upper-title">{x.itemName}</span>
                  <br />
                  <span className="lower-title">{x.itemValue}</span>
                </Col>
              </Row>
            </Col>
              ))
            }
            </Row> */}
      <div className="row">
            {data &&
              data.accountItems.map((x,i)=>x.idType!=5 && (
        <div className="col-lg-3 col-xxl-3 mb-5">
          <div className="account-info">
  
          <Row className="">
                <Col xl={2}>
                  <Image className="" src={"/media/korek/account-"+x.idType+".png"}></Image>
                </Col>{" "}
                <Col xl={10}>
                  <span className="upper-title">{x.itemName}</span>
                  <br />
                  <span className="lower-title">{x.itemValue}</span>
                </Col>
              </Row>
          </div>
              
        </div>
              ))
            }
            </div>
      <div className="row">
        {/* <div className="col-lg-6 col-xxl-4">
          <MixedWidget1 className="card-stretch gutter-b" />
        </div>
        <div className="col-lg-6 col-xxl-4">
          <ListsWidget9 className="card-stretch gutter-b" />
        </div> */}

        <div className="col-lg-6 col-xxl-6">
         {data && <StatsWidget11
            data={data.internetUsage}
            className="card-stretch gutter-b"
            symbolShape="circle"
            baseColor="primary"
          />}
        </div>

        <div className="col-lg-6 col-xxl-6">
         {data && <StatsWidget12
            data={data.voiceUsage}
            className="card-stretch gutter-b"
            symbolShape="circle"
            baseColor="danger"
          />}
        </div>
        {/* <div className="col-lg-6 col-xxl-4 order-1 order-xxl-1">
          <ListsWidget1 className="card-stretch gutter-b" />
        </div> */}
        <div className="col-xxl-12 order-2 order-xxl-1">
          
         {data &&<AdvanceTablesWidget2
            data={data.offerHistory} className="card-stretch gutter-b" />}
        </div>
        {/* <div className="col-lg-6 col-xxl-4 order-1 order-xxl-2">
          <ListsWidget3 className="card-stretch gutter-b" />
        </div>
        <div className="col-lg-6 col-xxl-4 order-1 order-xxl-2">
          <ListsWidget4 className="card-stretch gutter-b" />
        </div> */}
        {/* <div className="col-lg-12 col-xxl-4 order-1 order-xxl-2">
          <ListsWidget8 className="card-stretch gutter-b" />
        </div> */}
      </div>

    </div>
  );
}
