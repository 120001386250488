import React, { useEffect, useMemo, useState } from "react";
import objectPath from "object-path";
// LayoutContext
import { useHtmlClassService } from "../_core/MetronicLayout";
// Import Layout components
import { Header } from "./header/Header";
import { HeaderMobile } from "./header-mobile/HeaderMobile";
import { Aside } from "./aside/Aside";
import { Footer } from "./footer/Footer";
import { LayoutInit } from "./LayoutInit";
import { SubHeader } from "./subheader/SubHeader";
import { QuickPanel } from "./extras/offcanvas/QuickPanel";
import { QuickUser } from "./extras/offcanvas/QuickUser";
import { ScrollTop } from "./extras/ScrollTop";
import { StickyToolbar } from "./extras/StickyToolbar";
import { shallowEqual, useSelector } from "react-redux";
import { FooterMyKorek } from "./footer/FooterMyKorek";
import { SubHeaderScroll } from "./subheader/SubHeaderScroll";
import { HeaderBusiness } from "./headerBusiness/HeaderBusiness";
import { SubHeaderBusiness } from "./subheaderBusiness/SubHeaderBusiness";
import { SubHeaderBusinessScroll } from "./subheaderBusiness/SubHeaderBusinessScroll";
import { FooterBusiness } from "./footer/FooterBusiness";
import "../../_assets/sass/themes/layout/header/menu/dark.scss";
import "../../_assets/sass/themes/layout/header/base/dark.scss";
import { HeaderMobileBus } from "./header-mobile/HeaderMobileBus";
import { ScrollTopBus } from "./extras/ScrollTopBus";

export function Layout({ children }) {
    const user = useSelector((state) => state.auth.user, shallowEqual);
    const uiService = useHtmlClassService();
    const [navbar, setNavbar] = useState(false)
    // Layout settings (cssClasses/cssAttributes)
    const changeBackground = () => {
        if (window.scrollY >= 66) {
            setNavbar(true)
        } else {
            setNavbar(false)
        }
    }
    useEffect(() => {
        changeBackground()
        // adding the event when scroll change background
        window.addEventListener("scroll", changeBackground)
    })
    const layoutProps = useMemo(() => {
        return {
            layoutConfig: uiService.config,
            selfLayout: objectPath.get(uiService.config, "self.layout"),
            asideDisplay: objectPath.get(uiService.config, "aside.self.display"),
            subheaderDisplay: objectPath.get(uiService.config, "subheader.display"),
            desktopHeaderDisplay: objectPath.get(
                uiService.config,
                "header.self.fixed.desktop"
            ),
            contentCssClasses: uiService.getClasses("content", true),
            contentContainerClasses: uiService.getClasses("content_container", true),
            contentExtended: objectPath.get(uiService.config, "content.extended")
        };
    }, [uiService]);
    return layoutProps.selfLayout !== "blank" ? (
        window.location.pathname.split('/').slice(1)[0] == "business" ? <>
            {/*begin::Main*/}
            <HeaderMobileBus />
            <div className="d-flex flex-column flex-root business">
                {/*begin::Page*/}
                <div className="d-flex flex-row flex-column-fluid page">
                    {/*begin::Wrapper*/}
                    <div className={"d-flex flex-column flex-row-fluid wrapper px-0"} id="kt_wrapper">
                        {!navbar && <HeaderBusiness />}
                        {/*begin::Content*/}
                        <div
                            id="kt_content"
                            className={window.location.pathname.split('/').slice(1)[0] == "my-korek" ? `content padding-content ${layoutProps.contentCssClasses} d-flex flex-column flex-column-fluid` :
                                `content ${layoutProps.contentCssClasses} p-0 d-flex flex-column flex-column-fluid`}
                        >
                            {!navbar && layoutProps.subheaderDisplay && <SubHeaderBusiness />}
                            {navbar && layoutProps.subheaderDisplay && <SubHeaderBusinessScroll />}
                            {/*begin::Entry*/}
                            {!layoutProps.contentExtended && (
                                <div className="d-flex flex-column-fluid">
                                    {/*begin::Container*/}
                                    <div className={layoutProps.contentContainerClasses}>
                                        {children}
                                    </div>
                                    {/*end::Container*/}
                                </div>
                            )}

                            {layoutProps.contentExtended && { children }}
                            {/*end::Entry*/}
                        </div>
                        {/*end::Content*/}
                        {/* <Footer/> */}
                        {<FooterBusiness />}
                    </div>
                    {/*end::Wrapper*/}
                </div>
                {/*end::Page*/}
            </div>
            {/* <QuickUser/> */}
            {/* <QuickPanel/> */}
            <ScrollTop />
            {/* <StickyToolbar/> */}
            {/*end::Main*/}
            <LayoutInit />
        </> :
            <>
                {/*begin::Main*/}
                <HeaderMobile />
                <div className="d-flex flex-column flex-root">
                    {/*begin::Page*/}
                    <div className="d-flex flex-row flex-column-fluid page">
                        {layoutProps.asideDisplay && user && window.location.pathname.split('/').slice(1)[0] == "my-korek" && (<Aside navbar={navbar} />)}
                        {/*begin::Wrapper*/}
                        <div className={user && window.location.pathname.split('/').slice(1)[0] == "my-korek" ?
                            "d-flex flex-column flex-row-fluid wrapper" : "d-flex flex-column flex-row-fluid wrapper px-0"} id="kt_wrapper">
                            {!navbar && <Header />}
                            {/*begin::Content*/}
                            <div
                                id="kt_content"
                                className={window.location.pathname.split('/').slice(1)[0] == "my-korek" ? `content padding-content ${layoutProps.contentCssClasses} d-flex flex-column flex-column-fluid` :
                                    `content ${layoutProps.contentCssClasses} p-0 d-flex flex-column flex-column-fluid`}
                            >
                                {!navbar && layoutProps.subheaderDisplay && <SubHeader />}
                                {navbar && layoutProps.subheaderDisplay && <SubHeaderScroll />}

                                {/*begin::Entry*/}
                                {!layoutProps.contentExtended && (
                                    <div className="d-flex flex-column-fluid">
                                        {/*begin::Container*/}
                                        <div className={layoutProps.contentContainerClasses}>
                                            {children}
                                        </div>
                                        {/*end::Container*/}
                                    </div>
                                )}

                                {layoutProps.contentExtended && { children }}
                                {/*end::Entry*/}
                            </div>
                            {/*end::Content*/}
                            {/* <Footer/> */}
                            {window.location.pathname.split('/').slice(1)[0] == "my-korek" ? <FooterMyKorek /> : <Footer />}
                        </div>
                        {/*end::Wrapper*/}
                    </div>
                    {/*end::Page*/}
                </div>
                {/* <QuickUser/> */}
                {/* <QuickPanel/> */}<ScrollTopBus />
                {/* {window.location.pathname.split('/').slice(1)[0] == "business"?<ScrollTopBus/>:<ScrollTop/>} */}
                {/* <StickyToolbar/> */}
                {/*end::Main*/}
                <LayoutInit />
            </>
    ) : (
        // BLANK LAYOUT
        <div className="d-flex flex-column flex-root">{children}</div>
    );
}
