/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";
import { Row,Col, Image } from "react-bootstrap";
import { KTCookie } from "../../../../_assets/js/components/cookie";

export function AsideMenuList({ layoutProps }) {
  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu &&
          "menu-item-active"} menu-item-open menu-item-not-hightlighted`
      : "";
  };

  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        <li
          className={`menu-item blue-program-btn mb-5 ${getMenuItemActive("/my-korek/blue-program", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/my-korek/blue-program">
            <Row><Col style={{alignSelf:"center",paddingRight:"5px"}} xl={2}>
                    <Image alt="logo" src={toAbsoluteUrl(
                  "/media/korek/blue-prog-logo.png"
                )}/>
              </Col>
            <Col className="ml-4" xl={9}>
            <span className="menu-text">Blue Program</span>
            <span className="menu-desc">670,85 Pts</span>
            </Col></Row>
          </NavLink>
        </li>
        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/my-korek/dashboard", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/my-korek/dashboard">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
            </span>
            <span className="menu-text">Dashboard</span>
          </NavLink>
        </li>
        <li
          className={`menu-item ${getMenuItemActive("/my-korek/my-account", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/my-korek/my-account">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl(
                  "/media/svg/icons/Communication/Add-user.svg")} />
            </span>
            <span className="menu-text">My Account</span>
          </NavLink>
        </li>
        <li
          className={`menu-item ${getMenuItemActive("/my-korek/topup-my-account", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/my-korek/topup-my-account">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl(
                  "/media/svg/icons/Code/Plus.svg")} />
            </span>
            <span className="menu-text">Top Up My Account</span>
          </NavLink>
        </li>
        <li
          className={`menu-item ${getMenuItemActive("/my-korek/recharge-line", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/my-korek/recharge-line">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl(
                  "/media/svg/icons/Media/Repeat.svg")} />
            </span>
            <span className="menu-text">Recharge Line</span>
          </NavLink>
        </li>
          {
            KTCookie.getCookie("accountType") && KTCookie.getCookie("accountType") == 2 &&
        <li
          className={`menu-item ${getMenuItemActive("/my-korek/my-bills", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/my-korek/my-bills">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl(
                  "/media/svg/icons/General/Clipboard.svg")} />
            </span>
            <span className="menu-text">My Bills</span>
          </NavLink>
        </li>
}
        <li
          className={`menu-item ${getMenuItemActive("/my-korek/active-offers", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/my-korek/active-offers">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl(
                  "/media/svg/icons/Shopping/Sale2.svg")} />
            </span>
            <span className="menu-text">My Active Offers</span>
          </NavLink>
        </li>
        <li
          className={`menu-item ${getMenuItemActive("/my-korek/active-vas-offers", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/my-korek/active-vas-offers">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl(
                  "/media/svg/icons/Shopping/Sort3.svg")} />
            </span>
            <span className="menu-text">My Active VAS Offers</span>
          </NavLink>
        </li>
        <li
          className={`menu-item ${getMenuItemActive("/my-korek/support", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/my-korek/support">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl(
                  "/media/svg/icons/Devices/Headphones.svg")} />
            </span>
            <span className="menu-text">Support</span>
          </NavLink>
        </li>
      </ul>

      {/* end::Menu Nav */}
    </>
  );
}
