import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { put, takeLatest } from "redux-saga/effects";
import { getUserByToken,logout } from "./authCrud";
import { KTCookie } from "../../../../_metronic/_assets/js/components/cookie";
import { sendRequestDotNet } from "../../../../_utils/Axios";
import { useDispatch } from "react-redux";
import { getMainLoadData, getOffers, getOffersLoadData, getServices, initialization } from "../../UserProfile/_redux/Profile/profileActions";

export const actionTypes = {
  Login: "[Login] Action",
  Logout: "[Logout] Action",
  Register: "[Register] Action",
  UserRequested: "[Request User] Action",
  UserLoaded: "[Load User] Auth API",
  SetUser: "[Set User] Action",
};

const initialAuthState = {
  user: undefined,
  authToken: undefined,
  userProfile: undefined,
  accountItems: undefined,
};

export const reducer = persistReducer(
  {
    storage,
    key: "v726-demo1-auth",
    whitelist: ["authToken", "userProfile", "accountItems", "user"],
  },
  (state = initialAuthState, action) => {
    switch (action.type) {
      case actionTypes.Login: {
        const { authToken } = action.payload.data.token;
        KTCookie.setCookie(
          "idUserProfile",
          action.payload.data.profile.idUserProfile
        );
        KTCookie.setCookie("token", action.payload.data.token);
        // getUserByToken(action.payload.data.token).then((data) => {
        //   if(data.error){
        //   }
        //   else{
        //   profile=data.userProfile
        //   }
        // })
        // .catch((data) => {
        // })
        // .finally(() => {
        // });
        // localStorage.setItem('idUserProfile',action.payload.data.profile.idUserProfile);
        return { authToken, user: undefined };
      }

      case actionTypes.Register: {
        const { authToken } = action.payload;

        return { authToken, user: undefined };
      }

      case actionTypes.Logout: {
        // KTCookie.setCookie("token", KTCookie.getCookie("idGuest"));
        // KTCookie.setCookie("idUserType", "1");

        // let headers = {
        //   idusertype: "3",
        // };

        // // const dispatch = useDispatch();
        // sendRequestDotNet("Profile/Logout", {}).then((response) => {
        //   sendRequestDotNet("Registration/SkipLogin ", {}, headers).then(
        //     (response) => {
        //       KTCookie.setCookie("isSkipLogin", true);
        //       KTCookie.setCookie("idUserType", "1");
        //       KTCookie.setCookie("token", response.data.idGuest);
        //       KTCookie.setCookie("idGuest", response.data.idGuest);
        //       KTCookie.setCookie("logout", true);
        //     }
        //   );
        // });
        // sendRequestDotNet("Profile/Logout", {}).then((response) => {
        //   response.data.statusCode.code==0 && sendRequestDotNet("Registration/SkipLogin ", {}, headers).then(
        //     (response) => {
        //       KTCookie.setCookie("isSkipLogin", true);
        //       KTCookie.setCookie("idUserType", "1");
        //       KTCookie.setCookie("token", response.data.idGuest);
        //       KTCookie.setCookie("idGuest", response.data.idGuest);
        //       dispatch(initialization());
        //       dispatch(getMainLoadData());
        //       dispatch(getServices());
        //       dispatch(getOffersLoadData());
        //       dispatch(
        //         getOffers({
        //           idCategory: 0,
        //           idPlan: 0,
        //           isGetBestOffers: false,
        //           page: 0,
        //           pageSize: 100,
        //         })
        //       );
        //     }
        //   );
        // });
         return initialAuthState;
        // TODO: Change this code. Actions in reducer aren't allowed.
      }

      case actionTypes.UserLoaded: {
        // console.log(action.payload);
        const { user } = action.payload;
        // const userInf = user.userProfile
        // console.log(user);
        return { ...state, user };
      }

      case actionTypes.SetUser: {
        const { user } = action.payload;
        return { ...state, user };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  login: (data) =>
    // console.log(data)
    ({ type: actionTypes.Login, payload: data }),
  register: (authToken) => ({
    type: actionTypes.Register,
    payload: { authToken },
  }),
  logout: () => ({ type: actionTypes.Logout }),
  requestUser: (user) => ({
    type: actionTypes.UserRequested,
    payload: { user },
  }),
  fulfillUser: (user) => ({ type: actionTypes.UserLoaded, payload: { user } }),
  setUser: (user) => ({ type: actionTypes.SetUser, payload: { user } }),
};

export function* saga() {
  yield takeLatest(actionTypes.Login, function* loginSaga() {
    yield put(actions.requestUser());
  });

  yield takeLatest(actionTypes.Register, function* registerSaga() {
    yield put(actions.requestUser());
  });

  yield takeLatest(actionTypes.UserRequested, function* userRequested() {
    const { data: userProfile } = yield getUserByToken();
    yield put(actions.fulfillUser(userProfile));
  });
}
