/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, {useState} from "react";
import { Nav, Tab, Table } from "react-bootstrap";
import SVG from "react-inlinesvg";
import {toAbsoluteUrl} from "../../../_helpers";
import { FormattedMessage } from "react-intl";

export function AdvanceTablesWidget2({ className,data }) {
  const [key, setKey] = useState("Month");

  return (
    <div className={`card card-custom ${className}`}>
      {/* Head */}
      <div className="card-header border-0 pt-5">
        <h3 className="card-title align-items-start flex-column">
            <span className="text-dark-75 font-weight-bolder font-size-h3">
            <FormattedMessage id="DASHBOARD.LASTEST_ACT"></FormattedMessage>
          </span>
        </h3>
        {/* <div className="card-toolbar">
          <Tab.Container defaultActiveKey={key}>
            <Nav
              as="ul"
              onSelect={_key => setKey(_key)}
              className="nav nav-pills nav-pills-sm nav-dark-75"
            >
              <Nav.Item className="nav-item" as="li">
                <Nav.Link
                  eventKey="Month"
                  className={`nav-link py-2 px-4 ${
                    key === "Month" ? "active" : ""
                  }`}
                >
                  Month
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className="nav-item" as="li">
                <Nav.Link
                  eventKey="Week"
                  className={`nav-link py-2 px-4 ${
                    key === "Week" ? "active" : ""
                  }`}
                >
                  Week
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className="nav-item" as="li">
                <Nav.Link
                  eventKey="Day"
                  className={`nav-link py-2 px-4 ${
                    key === "Day" ? "active" : ""
                  }`}
                >
                  Day
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Tab.Container>
        </div> */}
      </div>
      {/* Body */}
      <div className="card-body pt-3 pb-0">
        <div style={{overflowY:"auto",height:"500px"}}>
        <div className="table-responsive">
          <Table className="blue-table" responsive>
            <thead>
              <tr>
                <th className="" ><FormattedMessage id="DASHBOARD.LASTEST_ACT.NAME">
                  </FormattedMessage></th>
                <th className="" ><FormattedMessage id="DASHBOARD.LASTEST_ACT.AMOUNT">
                  </FormattedMessage></th>
                <th className="" ><FormattedMessage id="DASHBOARD.LASTEST_ACT.DATE">
                  </FormattedMessage></th>
              </tr>
            </thead>
            <tbody>
              {data.map((x)=>(
                <tr>
          <td>{x.name}</td>
          <td>{x.amount}</td>
          <td>{x.date}</td></tr>
              ))}
            </tbody>
          </Table>
        </div>
        </div>
      </div>
    </div>
  );
}
