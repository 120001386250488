import {createSlice} from "@reduxjs/toolkit";

const initialCustomersState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  customerForEdit: undefined,
  offers: undefined,
  vasOffers: undefined,
  lastError: null,
  profile:undefined,
  userProfile:undefined
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const profileSlice = createSlice({
  name: "profile",
  initialState: initialCustomersState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    profileFetched: (state, action) => {
      state.actionsLoading = false;
      state.profile = action.payload.profile;
      state.userProfile = action.payload.userProfile;
      // console.log(state.userProfile)
      state.error = null;
    },
    profileSaved: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
    },
    offersUpdated: (state, action) => {
      state.actionsLoading = false;
      state.offers = action.payload.offers;
      state.error = null;
    },
    offersLoadedData: (state, action) => {
      state.actionsLoading = false;
      state.offerCategories = action.payload.offerCategories;
      state.offerPlans = action.payload.offerPlans;
      state.error = null;
    },
    mainLoadedData: (state, action) => {
      state.actionsLoading = false;
      state.navbarItems = action.payload.navbarItems;
      state.showBills = action.payload.showBills;
      state.faqCategories = action.payload.faqCategories;
      state.socialMedia = action.payload.socialMedia;
      state.appLinks = action.payload.appLinks;
      state.offerCategories = action.payload.offerCategories;
      state.offerPlans = action.payload.offerPlans;
      state.error = null;
    },
    numberLoadedData: (state, action) => {
      state.actionsLoading = false;
      state.prefixes = action.payload.prefixes;
      state.positions = action.payload.positions;
      state.priceRanges = action.payload.priceRanges;
      state.governorates = action.payload.governorates;
      state.error = null;
    },
    FAQDataLoaded: (state, action) => {
      state.actionsLoading = false;
      state.faqData = action.payload.list;
      state.error = null;
    },
    initialized: (state, action) => {
      state.actionsLoading = false;
      state.splittedBanners = action.payload.splittedBanners;
      state.servicesInit = action.payload.services;
      state.middleBanners = action.payload.middleBanners;
      state.bundlesInit = action.payload.bundles;
      state.newsInit = action.payload.news;
      state.faqSectionsInit = action.payload.faqSections;
      state.error = null;
    },
    servicesLoaded: (state, action) => {
      state.actionsLoading = false;
      state.services = action.payload.services;
      state.error = null;
    },
    vasOffersLoaded: (state, action) => {
      state.actionsLoading = false;
      state.vasOffers = action.payload.list;
      state.error = null;
    },
    connectedDevices: (state, action) => {
      state.actionsLoading = false;
      state.list = action.payload.list;
      state.error = null;
    },
    blockedNumber: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
    },
    // // findCustomers
    // customersFetched: (state, action) => {
    //   const { totalCount, entities } = action.payload;
    //   state.listLoading = false;
    //   state.error = null;
    //   state.entities = entities;
    //   state.totalCount = totalCount;
    // },
    // // createCustomer
    // customerCreated: (state, action) => {
    //   state.ewactionsLoading = false;
    //   state.error = null;
    //   state.entities.push(action.payload.customer);
    // },
    // // updateCustomer
    // customerUpdated: (state, action) => {
    //   state.error = null;
    //   state.actionsLoading = false;
    //   state.entities = state.entities.map(entity => {
    //     if (entity.id === action.payload.customer.id) {
    //       return action.payload.customer;
    //     }
    //     return entity;
    //   });
    // },
    // // deleteCustomer
    // customerDeleted: (state, action) => {
    //   state.error = null;
    //   state.actionsLoading = false;
    //   state.entities = state.entities.filter(el => el.id !== action.payload.id);
    // },
    // // deleteCustomers
    // customersDeleted: (state, action) => {
    //   state.error = null;
    //   state.actionsLoading = false;
    //   state.entities = state.entities.filter(
    //     el => !action.payload.ids.includes(el.id)
    //   );
    // },
    // // customersUpdateState
    // customersStatusUpdated: (state, action) => {
    //   state.actionsLoading = false;
    //   state.error = null;
    //   const { ids, status } = action.payload;
    //   state.entities = state.entities.map(entity => {
    //     if (ids.findIndex(id => id === entity.id) > -1) {
    //       entity.status = status;
    //     }
    //     return entity;
    //   });
    // }
  }
});
