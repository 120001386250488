import React, { useEffect, useMemo, useState } from "react";
import {
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../../_metronic/_partials/controls";
import { Card as CardC } from "../../../../../_metronic/_partials/controls";
import { RechargeLinesFilter } from "./rechargeLines-filter/RechargeLinesFilter";
import { RechargeLinesTable } from "./rechargeLines-table/RechargeLinesTable";
import { useRechargeLinesUIContext } from "./RechargeLinesUIContext";
import "../../../../../_metronic/_assets/sass/pages/my-korek/my-active-offers.scss";
import { Button, Col, Form, Nav, Row, Tab } from "react-bootstrap";
import { Chip, Divider } from "@material-ui/core";
// import "../../../../../_metronic/_assets/sass/pages/home/home.scss";
import { makeStyles } from '@material-ui/core/styles';
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { applyLoan, autoCreditLoan, fetchLoansDetails, rechargePrePaid, rechargeScratchCard } from "../../_redux/rechargeLines/rechargeLinesActions";
import { FormattedMessage, useIntl } from "react-intl";

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    // justifyContent: 'lef',
    flexWrap: 'wrap',
  },
  chip: {
    margin: theme.spacing(1),
  },
}));

export function RechargeLinesCard() {
  const rechargeLinesUIContext = useRechargeLinesUIContext();
  const classes = useStyles();
  const intl = useIntl()
  
  const user = useSelector((state) => state.auth.user, shallowEqual);

  const { currentState } = useSelector(
    (state) => ({ currentState: state.rechargeLines }),
    shallowEqual
  );
  const { autoLoanEnabled,loans } = currentState;
  const dispatch = useDispatch()
  const rechargeLinesUIProps = useMemo(() => {
    return {
      ids: rechargeLinesUIContext.ids,
      queryParams: rechargeLinesUIContext.queryParams,
      setQueryParams: rechargeLinesUIContext.setQueryParams,
      openUpdateRechargeLinesStatusDialog:
        rechargeLinesUIContext.openUpdateRechargeLinesStatusDialog,
    };
  }, [rechargeLinesUIContext]);

  function handleClick() {
    
    dispatch(
      applyLoan({
        idLoan: selectedCreditLoan.idLoan,
        loanType: selectedCreditLoan.loanType,
      })
    );
    // alert('You clicked the Chip.');
  }
  const [voucher, setVoucher] = useState("");
  const [amount, setAmount] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [selectedCreditLoan, setSelectedCreditLoan] = useState();

  useEffect(() => {
    dispatch(fetchLoansDetails({
      loanType:0
    }))
  }, []);

  let loansArray =
  [{
    loanName:intl.formatMessage({id:"RECHARGE.CREDIT_LOAN"}),
    loanType:1
  },{
    loanName:intl.formatMessage({id:"RECHARGE.DATA_LOAN"}),
    loanType:2
  },{
    loanName:intl.formatMessage({id:"RECHARGE.BUNDLE_LOAN"}),
    loanType:3
  },{
    loanName:intl.formatMessage({id:"RECHARGE.VOICE_LOAN"}),
    loanType:4
  }]  
  return (
    <div className="my-active-offers">
      <div className="card card-custom card-stretch mb-5">
        <Tab.Container defaultActiveKey="first">
          <Row style={{ padding: "16px 16px 0px 16px" }}>
            <Col xl={6}>
              <div className="head-title">
                <FormattedMessage id="RECHARGE.ENTER_NBR"></FormattedMessage>
              </div>
            </Col>
            <Col xl={6}>
              <Nav variant="tabs" className="flex-row mr-0 ml-auto">
                <Nav.Item>
                  <Nav.Link eventKey="first"><FormattedMessage id="RECHARGE.NASWALLET"></FormattedMessage></Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="second"><FormattedMessage id="RECHARGE.SCRATCH"></FormattedMessage></Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>
          </Row>
          <div className="card-body pt-0">
            <Tab.Content className="mt-5">
              <Tab.Pane className="recharge-tab" eventKey="first">
                <Row>
                  <Col xl={7}>
                    <input
                      onChange={(e) => setMobileNumber(e.target.value)}
                      value={mobileNumber}
                      type="number"
                      className="input-home tel-input"
                      placeholder={intl.formatMessage({id:"RECHARGE.ENTER_MOB_NBR"})}
                    />
                    
                    {/* <Form.Text id="passwordHelpBlock" className="voucher-helper" muted>
                      {intl.formatMessage({id:"HOME.RECHARGE.MSISDN.HELPER"})}
                    </Form.Text> */}
                  </Col>
                  <Col xl={3}>
                    <input
                      onChange={(e) => setAmount(e.target.value)}
                      value={amount}
                      className="input-home"
                      type="number"
                      placeholder={intl.formatMessage({id:"RECHARGE.ENTER_AMOUNT"})}
                    />
                  </Col>
                  <Col className="text-center mx-auto input-btn py-5" xl={2}>
                    <Button
                    disabled={!mobileNumber || !amount}
                      onClick={() => {
                        dispatch(
                          rechargePrePaid({
                            receiverMsisdn: mobileNumber,
                            amount: parseInt(amount),
                          })
                        );
                        setMobileNumber("")
                        setAmount("")
                      }} className="btn btn-warning">
                      <b>
                <FormattedMessage id="RECHARGE.CONTINUE"></FormattedMessage></b>
                    </Button>
                  </Col>
                </Row>
              </Tab.Pane>
              <Tab.Pane className="recharge-tab" eventKey="second">
                <Row>
                  <Col xl={10}>
                    <input
                      onChange={(e) => setVoucher(e.target.value)}
                      value={voucher}
                      className="input-home"
                      placeholder={intl.formatMessage({id:"RECHARGE.VOUCHER_NUMBER"})}
                    />
                  </Col>
                  <Col className="text-center mx-auto input-btn py-5" xl={2}>
                    <Button
                    disabled={!voucher}
                      onClick={() => {
                        dispatch(
                          rechargeScratchCard({
                            msisdn: user.userProfile.msisdn,
                            voucherNumber: voucher,
                            rechargeType: 0,
                          })
                        );
                        setVoucher("")
                      }}
                      className="btn btn-warning"
                    >
                      <b><FormattedMessage id="RECHARGE.CONTINUE"></FormattedMessage></b>
                    </Button>
                  </Col>
                </Row>
              </Tab.Pane>
            </Tab.Content>
          </div>
        </Tab.Container>
      </div>
      <div className="card card-custom card-stretch mb-5">
        <Tab.Container defaultActiveKey="first">
          <Row style={{ padding: "16px 16px 0px 16px" }}>
            <Col xl={6}>
              <div className="head-title">
                <FormattedMessage id="RECHARGE.TRANSFER_BALANCE"></FormattedMessage></div>
            </Col>
            <Col xl={6}>
              {/* <Nav variant="tabs" className="flex-row mr-0 ml-auto">
                <Nav.Item>
                  <Nav.Link eventKey="first">Via Naswallet</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="second">Via Scratch Card</Nav.Link>
                </Nav.Item>
              </Nav> */}
            </Col>
          </Row>
          <div className="card-body pt-0">
            <Tab.Content className="mt-5">
              <Tab.Pane className="recharge-tab" eventKey="first">
                <Row>
                  <Col xl={10}>
                    <input
                      className="input-home"
                      placeholder="Enter Your Mobile Number"
                    />
                  </Col>
                  <Col className="text-center mx-auto input-btn py-5" xl={2}>
                    <Button className="btn btn-warning">
                      <b>
                <FormattedMessage id="RECHARGE.TRANSFER"></FormattedMessage></b>
                    </Button>
                  </Col>
                </Row>
              </Tab.Pane>
            </Tab.Content>
          </div>
        </Tab.Container>
      </div>
      {
        loansArray.map((l,i)=>(

          (<div key={i} className="card card-custom card-stretch mb-5">
        <Row style={{ padding: "16px 16px 0px 16px" }}>
          <Col xl={6}>
            <div className="head-title">{l.loanName}</div>
          </Col>
        </Row>
        <div className="card-body py-0">
          <Row>
            <Col xl={12}>
              <div className={classes.root}>
                {
                  loans && loans.filter(x=>x.loanType == l.loanType).length>0 ?loans.filter(x=>x.loanType == l.loanType).map((x,i)=>(
                                    <Chip
                                    key={i}
                                    
                  // avatar={<Avatar>MB</Avatar>}
                  label={x.subDescription}
                  color="secondary"
                  onClick={()=>setSelectedCreditLoan(x)}
                  className={classes.chip}
                  variant={selectedCreditLoan && selectedCreditLoan.idLoan == x.idLoan?"default":"outlined"}
                />
                  )):loans ? <div className="mb-4">
                    <FormattedMessage id="RECHARGE.NO_LOANS_AVAILABLE"></FormattedMessage></div>:<span className="m-5 spinner spinner-dark"></span>
                }

              </div>
            </Col>
            {loans && loans.filter(x=>x.loanType == l.loanType).length>0 &&
            <Col className="text-left input-btn mx-3  pb-5" xl={2}>
              <Button disabled={!(selectedCreditLoan && selectedCreditLoan.loanType == l.loanType)} onClick={handleClick} className="btn btn-warning">
                <b><FormattedMessage id="RECHARGE.APPLY"></FormattedMessage></b>
              </Button>
            </Col>}
            {l.loanType==1 &&
            <Col className="text-left input-btn pb-5" xl={12}>
                            <Col className="auto-renewal">
                              <div className="checkbox-inline">
                                <label className="checkbox m-0">
                                  <input
                                    type="checkbox"
                                    checked={autoLoanEnabled && autoLoanEnabled}
                                    onChange={() => {
                                      dispatch(
                                        autoCreditLoan({
                                          action:autoLoanEnabled && autoLoanEnabled?0:1
                                        })
                                      );
                                      // dispatch(fetchMyActiveOffers());

                                    }}
                                  />
                                  <span></span>
                                  <FormattedMessage id="RECHARGE.AUTO_CREDIT_LOAN"></FormattedMessage>
                                </label>
                              </div>
                            </Col>
            </Col>}
          </Row>
        </div>
      </div>)
        ))
      }
      {/* <div className="card card-custom card-stretch mb-5">
        <Row style={{ padding: "16px 16px 0px 16px" }}>
          <Col xl={6}>
            <div className="head-title">Credit Loan</div>
          </Col>
        </Row>
        <div className="card-body pt-0">
          <Row>
            <Col xl={12}>
              <div className={classes.root}>
                {
                  loans && loans.filter(x=>x.loanType == 1).map((x,i)=>(
                                    <Chip
                                    key={i}
                                    
                  // avatar={<Avatar>MB</Avatar>}
                  label={x.subDescription}
                  color="secondary"
                  onClick={()=>setSelectedCreditLoan(x)}
                  className={classes.chip}
                  variant={selectedCreditLoan && selectedCreditLoan.idLoan == x.idLoan?"default":"outlined"}
                />
                  ))
                }

              </div>
            </Col>
            <Col className="text-left mx-auto input-btn py-5" xl={2}>
              <Button onClick={handleClick} className="btn btn-warning">
                <b>Apply</b>
              </Button>
            </Col>
          </Row>
        </div>
      </div> */}
      <CardC>
        <CardHeader title={intl.formatMessage({id:"RECHARGE.RECHARGE_HISTORY"})}>
          <CardHeaderToolbar>
            <RechargeLinesFilter />
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody className="p-0">
          {/* {rechargeLinesUIProps.ids.length > 0 && (
          <>
            <RechargeLinesGrouping />
          </>
        )} */}
          <RechargeLinesTable />
        </CardBody>
      </CardC>
    </div>
  );
}
