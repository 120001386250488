/* eslint-disable no-unused-vars */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState, useMemo } from "react";
import { Nav, Tab, Dropdown, OverlayTrigger, Tooltip, NavDropdown, Row, Col } from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import SVG from "react-inlinesvg";
import objectPath from "object-path";
import { useHtmlClassService } from "../../../_core/MetronicLayout";
import { toAbsoluteUrl } from "../../../../_helpers";
import { DropdownTopbarItemToggler } from "../../../../_partials/dropdowns";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";

const perfectScrollbarOptions = {
  wheelSpeed: 2,
  wheelPropagation: false,
};

let navBarBuss = [
  {
    id: "1",
    title: "SOHO",
    redirectionLink: "",
    sections: [
      {
        title: "Call Center System",
        redirectionLink: "/business/soho/call-center-system",
      },
      {
        title: "Postpaid Bundles",
        redirectionLink: "/business/soho/postpaid-bundles",
      },
      {
        title: "Prepaid Bundles",
        redirectionLink: "/business/soho/prepaid-bundles",
      },
      {
        title: "Data Packages",
        redirectionLink: "/business/soho/data-packages",
      },
      {
        title: "International & Roaming",
        redirectionLink: "/business/soho/international-roaming",
      },
    ],
  },
  {
    id: "2",
    title: "SME",
    redirectionLink: "",
    sections: [
      {
        title: "Call Center System",
        redirectionLink: "/business/sme/call-center-system",
      },
      {
        title: "Postpaid Bundles",
        redirectionLink: "/business/sme/postpaid-bundles",
      },
      {
        title: "prepaid-bundles",
        redirectionLink: "/business/sme/prepaid-bundles",
      },
      {
        title: "Data packages",
        redirectionLink: "/business/sme/data-packages",
      },
      {
        title: "International & Roaming",
        redirectionLink: "/business/sme/international-roaming",
      },
      {
        title: "Bulk SMS",
        redirectionLink: "/business/sme/bulk-sms",
      },
      {
        title: "Short code & Toll Free",
        redirectionLink: "/business/sme/short-code-toll-free",
      },
      {
        title: "IoT",
        redirectionLink: "/business/sme/iot",
      },
    ],
  },
  {
    id: "3",
    title: "Enterprise",
    redirectionLink: "",
    sections: [
      {
        title: "Call Center System",
        redirectionLink: "/business/enterprise/call-center-system",
      },
      {
        title: "Postpaid Bundles",
        redirectionLink: "/business/enterprise/postpaid-bundles",
      },
      {
        title: "prepaid-bundles",
        redirectionLink: "/business/enterprise/prepaid-bundles",
      },
      {
        title: "Data packages",
        redirectionLink: "/business/enterprise/data-packages",
      },
      {
        title: "International & Roaming",
        redirectionLink: "/business/enterprise/international-roaming",
      },
      {
        title: "Bulk SMS",
        redirectionLink: "/business/enterprise/bulk-sms",
      },
      {
        title: "Short code & Toll Free",
        redirectionLink: "/business/enterprise/short-code-toll-free",
      },
    ],
  },
  {
    id: "4",
    title: "Government",
    redirectionLink: "",

    sections: [
      {
        title: "Call Center System",
        redirectionLink: "/business/government/call-center-system",
      },
      {
        title: "Postpaid Bundles",
        redirectionLink: "/business/government/postpaid-bundles",
      },
      {
        title: "prepaid-bundles",
        redirectionLink: "/business/government/prepaid-bundles",
      },
      {
        title: "Data packages",
        redirectionLink: "/business/government/data-packages",
      },
      {
        title: "International & Roaming",
        redirectionLink: "/business/government/international-roaming",
      },
      {
        title: "Bulk SMS",
        redirectionLink: "/business/government/bulk-sms",
      },
      {
        title: "Short code & Toll Free",
        redirectionLink: "/business/government/short-code-toll-free",
      },
      {
        title: "IoT",
        redirectionLink: "/business/government/iot",
      },
    ],
  },
  {
    id: "5",
    title: "VIP",
    redirectionLink: "",

    sections: [
      {
        title: "Postpaid Bundles",
        redirectionLink: "/business/vip/postpaid-bundles",
      },
      {
        title: "Mobile Plans",
        redirectionLink: "/business/vip/mobile-plans",
      },
      {
        title: "Data packages",
        redirectionLink: "/business/vip/data-packages",
      },
      {
        title: "International & Roaming",
        redirectionLink: "/business/vip/international-roaming",
      },
      {
        title: "Elite Club",
        redirectionLink: "/business/vip/elite-club",
      },
    ],
  },
];
export function UserNotificationsDropdownBuss() {
  const [key, setKey] = useState("Alerts");
  const bgImage = toAbsoluteUrl("/media/misc/bg-1.jpg");
  let history = useHistory();

  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      offcanvas:
        objectPath.get(uiService.config, "extras.notifications.layout") ===
        "offcanvas",
    };
  }, [uiService]);
  const [show, setShow] = useState("");

  const navbarItems = useSelector(
    (state) => state.profileSlice.navbarItems,
    shallowEqual
  );
  const showDropdown = (e) => {
    setShow(e);
  };
  const hideDropdown = (e) => {
    setShow("");
  };
  const [showServices, setShowServices] = useState(false);
  const routeChange = (path) => {
    // let path = `/korek-offers/all-offers`;
    history.push(path);
  };
  return (
    <>
      {layoutProps.offcanvas && (
        <div className="topbar-item">
          <div
            className="btn btn-icon btn-clean btn-lg mr-1 pulse pulse-light"
            id="kt_quick_notifications_toggle"
          >
            <span className="svg-icon svg-icon-xl svg-icon-light">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Compiling.svg")} />
            </span>
            <span className="pulse-ring"></span>
          </div>
        </div>
      )}
      {!layoutProps.offcanvas && (
        <Dropdown drop="down" alignRight>
          <Dropdown.Toggle
            as={DropdownTopbarItemToggler}
            id="kt_quick_notifications_toggle"
          >
            {/* <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip id="user-notification-tooltip">
                  User Notifications
                </Tooltip>
              }
            > */}
            <div
              className="btn btn-icon btn-clean btn-lg mr-1 pulse pulse-light"
              id="kt_quick_notifications_toggle"
            >
              <span className="svg-icon svg-icon-xl svg-icon-light">
                <SVG
                  src={toAbsoluteUrl("/media/svg/icons/General/Other1.svg")}
                />
              </span>
              {/* <span className="pulse-ring"></span>
                <span className="pulse-ring" /> */}
            </div>
            {/* </OverlayTrigger> */}
          </Dropdown.Toggle>

          <Dropdown.Menu className="dropdown-menu p-0 m-0 dropdown-menu-left dropdown-menu-anim-up dropdown-menu-lg">
            {/* <form> */}
            {/** Head */}
            {/* <div
                className="d-flex flex-column pt-12 bgi-size-cover bgi-no-repeat rounded-top"
                style={{ backgroundImage: `url(${bgImage})` }}
              >
                <h4 className="d-flex flex-center rounded-top">
                  <span className="text-white">User Notifications</span>
                  <span className="btn btn-text btn-success btn-sm font-weight-bold btn-font-md ml-2">
                    23 new
                  </span>
                </h4>
              </div> */}
            <Row className="px-4" style={{ width: "100%", placeContent: 'space-between' }}>
              <Nav.Link className="menu-link" to="/about-us">
                <span className="menu-text"
                  style={{ color: "black", fontWeight: "600" }}><b><FormattedMessage id="FOOTER.ABOUT_US" /></b></span>
                {layoutProps.rootArrowEnabled && (<i className="menu-arrow" />)}
              </Nav.Link>
              <a target="_blank" className="menu-link nav-link" href="https://careers.korektel.com/">
                <span className="menu-text"
                  style={{ color: "black", fontWeight: "600" }}><b><FormattedMessage id="FOOTER.CAREERS" /></b></span>
                {layoutProps.rootArrowEnabled && (<i className="menu-arrow" />)}
              </a>
              <Nav.Link className="menu-link" to="/our-location">
                <span className="menu-text"
                  style={{ color: "black", fontWeight: "600" }}><b><FormattedMessage id="FOOTER.OUR_LOCATION" /></b></span>
                {layoutProps.rootArrowEnabled && (<i className="menu-arrow" />)}
              </Nav.Link>
              <Nav.Link className="menu-link" to="/contact">
                <span className="menu-text"
                  style={{ color: "black", fontWeight: "600" }}><b><FormattedMessage id="FOOTER.CONTACT" /></b></span>
                {layoutProps.rootArrowEnabled && (<i className="menu-arrow" />)}
              </Nav.Link>
            </Row>

            {/*end::Header Nav*/}
            {navBarBuss &&
              navBarBuss.filter(x => x.id != "5").map((x, i) =>
                x.sections.length > 0 ? (
                  <NavDropdown
                    style={{ color: "black", fontWeight: "600", }}
                    key={x.id}
                    title={x.title}
                    className="pr-5 dropdown-header-animation mobile-a"
                    id={`offcanvasNavbarDropdown-expand-${"xl"}`}
                  // renderMenuOnMount={true}
                  // onMouseEnter={() => showDropdown(x.id)}
                  // onMouseLeave={() => hideDropdown(x.id)}
                  // onClick={() => routeChange(x.redirectionLink)}
                  // show={show == x.id}
                  >
                    {/* <Nav.Link
                      id={`offcanvasNavbarDropdown-expand-${"xl"}`}
                      className="px-5 dropdown-header-animation dropdown-item"
                      href={x.redirectionLink}
                    >
                      <b>All</b>
                    </Nav.Link> */}
                    {x.sections &&
                      x.sections.map((y, i) => (
                        <>
                          <NavDropdown.Item
                            className="dropdown-header-animation" href={y.redirectionLink}>
                            <b>{y.title}</b>
                          </NavDropdown.Item>
                          {y.subsections &&
                            y.subsections.map((z) => (
                              <NavDropdown.Item
                                className="dropdown-header-animation" href={z.redirectionLink}>
                                {z.title}
                              </NavDropdown.Item>
                            ))}</>
                      ))}
                  </NavDropdown>
                ) : (
                  <Nav.Link
                    id={`offcanvasNavbarDropdown-expand-${"xl"}`}
                    className="px-5 dropdown-header-animation"
                    style={{ color: "black", fontWeight: "600" }}
                    href={x.redirectionLink}
                  >
                    {x.title}
                  </Nav.Link>
                )
              )}
            {/* </form> */}
          </Dropdown.Menu>
        </Dropdown>
      )}
    </>
  );
}
