/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";
import {Link} from "react-router-dom";
import { useLang } from "../../../../i18n";
import {GetLogo} from "../../../../_helpers/GetLogo";
import { FormattedMessage } from "react-intl";

export function HeaderMenu({ layoutProps }) {
    const location = useLocation();
    const lang = useLang()
    const getMenuItemActive = (url) => {
        return checkIsActive(location, url) ? "menu-item-active" : "";
    }

    // const getHeaderLogo = () => {
    //     console.log(lang)
    //     let result = lang=="ar"?"korek-logo-white-ar.png":"logo_white.png";
    //     if (layoutProps.headerSelfTheme && layoutProps.headerSelfTheme !== "dark") {
    //         result = "logo_large_1_.png";
    //     }
    //     return toAbsoluteUrl(`/media/logos/${result}`);
    // };
    return <div
        id="kt_header_menu"
        className={`header-menu header-menu-mobile side-header ${layoutProps.ktMenuClasses}`}
        {...layoutProps.headerMenuAttributes}
    >
        {/*begin::Header Nav*/}
        <ul className={`menu-nav ${layoutProps.ulClasses}`}>
            
                {/* <div className="header-logo"> */}
                  {/* <Link to=""> */}
            <li className={`menu-item menu-item-rel`}>
                <NavLink className="menu-link" to="/">
                    <img alt="logo" src={GetLogo("dark")}/>
                </NavLink>
            </li>
                  {/* </Link> */}
                {/* </div> */}
            {/*begin::1 Level*/}
            <li className={`menu-item menu-item-rel ${getMenuItemActive('/home')}`}>
                <NavLink className="menu-link" to="/">
                    <span className="menu-text"><FormattedMessage id="FOOTER.PERSONAL"></FormattedMessage></span>
                    {layoutProps.rootArrowEnabled && (<i className="menu-arrow" />)}
                </NavLink>
            </li>
            <li className={`menu-item menu-item-rel ${getMenuItemActive('/business/home')}`}>
                <NavLink className="menu-link" to="/business/home">
                    <span className="menu-text"><FormattedMessage id="FOOTER.Business"></FormattedMessage></span>
                    {layoutProps.rootArrowEnabled && (<i className="menu-arrow" />)}
                </NavLink>
            </li>
            {/*end::1 Level*/}

        </ul>
        {/*end::Header Nav*/}
    </div>;
}
