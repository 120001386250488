// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useEffect, useMemo } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useActiveOffersUIContext } from "../ActiveOffersUIContext";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { FormattedMessage, useIntl } from "react-intl";

export function ActiveOffersTable() {
  // ActiveOffers UI Context
  const activeOffersUIContext = useActiveOffersUIContext();
  const activeOffersUIProps = useMemo(() => {
    return {
      ids: activeOffersUIContext.ids,
      setIds: activeOffersUIContext.setIds,
      queryParams: activeOffersUIContext.queryParams,
      setQueryParams: activeOffersUIContext.setQueryParams,
      openEditActiveOfferPage: activeOffersUIContext.openEditActiveOfferPage,
      openDeleteActiveOfferDialog: activeOffersUIContext.openDeleteActiveOfferDialog,
    };
  }, [activeOffersUIContext]);

  // Getting curret state of activeOffers list from store (Redux)
  const { currentState } = useSelector(
    (state) => ({ currentState: state.activeOffers }),
    shallowEqual
  );
  const { listOffersHistory } = currentState;
  // ActiveOffers Redux state
  const dispatch = useDispatch();
  useEffect(() => {
    // server call by queryParams
    // dispatch(actions.fetchActiveOffers(activeOffersUIProps.queryParams));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeOffersUIProps.queryParams, dispatch]);
  // Table columns
  const intl = useIntl()
  return (
    <>
      <table className="table table table-head-custom table-vertical-center overflow-hidden">
        <thead>
          <tr>
            <th><FormattedMessage id="ACTIVE_OFFERS.OFFER_NAME"></FormattedMessage></th>
            <th><FormattedMessage id="ACTIVE_OFFERS.AMOUNT"></FormattedMessage></th>
            <th><FormattedMessage id="ACTIVE_OFFERS.DATE"></FormattedMessage></th>
          </tr>
        </thead>
        <tbody>
          {listOffersHistory ? (
            listOffersHistory.map((of) => (
              <tr key={`id${of.name}`}>
                <td>{of.name}</td>
                <td>{of.amount}</td>
                <td>{of.date}</td>
              </tr>
            ))
          ) : (
            <SkeletonTheme>
              <tbody>
                <tr>
                  <td>
                    <Skeleton height={20} count={3} />
                  </td>
                  <td>
                    <Skeleton height={20} count={3} />
                  </td>
                  <td>
                    <Skeleton height={20} count={3} />
                  </td>
                </tr>
              </tbody>
            </SkeletonTheme>
          )}
        </tbody>

      </table>
    </>
  );
}
