/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Col, Image, Row } from "react-bootstrap";
import { FormattedMessage } from "react-intl";

export function  KorekApp(){
  
  return (
      <div style={{width:"100%"}} className="padding-container korek-app maxWidthCont">
        <Row>
          <Col xl={9} sm={12}>
          <h1 className="title"><FormattedMessage id="HOME.KORE_APP"></FormattedMessage></h1>
          <h6 className="py-3"><FormattedMessage id="HOME.KORE_APP.SUB_TITLE"></FormattedMessage></h6>

          <Row>
                  <a target="_blank" href="https://apps.apple.com/us/app/my-korek/id1245137913">
              <Col className="my-2" xl={2}>
                      <Image height="40" src="/media/korek/app-store.png"></Image> </Col></a>
                  <a target="_blank" href="https://play.google.com/store/apps/details?id=com.evampsaanga.korek&hl=en_US">
              <Col className="my-2" xl={2}>
                      <Image height="40" src="/media/korek/google-play.png"></Image></Col></a>
                  <a target="_blank" href="https://appgallery.huawei.com/app/C101313017">
              <Col className="my-2" xl={2}>
                      <Image height="40" src="/media/korek/app-gallery.png"></Image></Col></a>
            </Row>           
           {/* <p className="py-3">Get faster and more GB internet at a reasonable price… This offer includes a variety of packages (daily, weekly and monthly)…</p> */}
          </Col>
          <Col className="text-xl-right my-2" xl={3} sm={12}>
          <Image className="" width="150" src="/media/korek/qr.png"></Image>
        </Col>
        </Row>
      </div>
  );
}
