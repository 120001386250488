import {createSlice} from "@reduxjs/toolkit";

const initialRechargeLinesState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  rechargeLinesList: null,
  historyPrepaidRechargeList: null,
  rechargeLineForEdit: undefined,
  lastError: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const rechargeLinesSlice = createSlice({
  name: "rechargeLines",
  initialState: initialRechargeLinesState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    myRechargeLinesFetched: (state, action) => {
      const { rechargeLinesList } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.rechargeLinesList = rechargeLinesList;
    },
    historyPrepaidRechargeFetched: (state, action) => {
      const { list } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.historyPrepaidRechargeList = list;
    },
    loansDetailsFetched: (state, action) => {
      const { loans,autoLoanEnabled } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.loans = loans;
      state.autoLoanEnabled = autoLoanEnabled;
    },
    scratchCardRecharged: (state, action) => {
      state.listLoading = false;
      state.error = null;
    },
    prePaidRecharged: (state, action) => {
      state.listLoading = false;
      state.error = null;
    },
    loanApplied: (state, action) => {
      state.listLoading = false;
      state.error = null;
    },
    autoCreditLoanDone: (state, action) => {
      state.listLoading = false;
      state.error = null;
    },
    // rechargeLinesUpdateState
    rechargeLinesStatusUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      const { ids, status } = action.payload;
      state.entities = state.entities.map(entity => {
        if (ids.findIndex(id => id === entity.id) > -1) {
          entity.status = status;
        }
        return entity;
      });
    }
  }
});
