// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useEffect, useMemo } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../_redux/rechargeLines/rechargeLinesActions";
import * as uiHelpers from "../RechargeLinesUIHelpers";
import {
  getSelectRow,
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
} from "../../../../../../_metronic/_helpers";
import * as columnFormatters from "./column-formatters";
import { Pagination } from "../../../../../../_metronic/_partials/controls";
import { useRechargeLinesUIContext } from "../RechargeLinesUIContext";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { FormattedMessage } from "react-intl";

export function RechargeLinesTable() {
  // RechargeLines UI Context
  const rechargeLinesUIContext = useRechargeLinesUIContext();
  const rechargeLinesUIProps = useMemo(() => {
    return {
      ids: rechargeLinesUIContext.ids,
      setIds: rechargeLinesUIContext.setIds,
      queryParams: rechargeLinesUIContext.queryParams,
      setQueryParams: rechargeLinesUIContext.setQueryParams,
      openEditRechargeLinePage: rechargeLinesUIContext.openEditRechargeLinePage,
      openDeleteRechargeLineDialog: rechargeLinesUIContext.openDeleteRechargeLineDialog,
    };
  }, [rechargeLinesUIContext]);

  // Getting curret state of rechargeLines list from store (Redux)
  const { currentState } = useSelector(
    (state) => ({ currentState: state.rechargeLines }),
    shallowEqual
  );
  const { historyPrepaidRechargeList } = currentState;
  // RechargeLines Redux state
  const dispatch = useDispatch();
  useEffect(() => {
    // clear selections list
    rechargeLinesUIProps.setIds([]);
    // server call by queryParams
    // dispatch(actions.fetchHistoryPrepaidRecharge(rechargeLinesUIProps.queryParams));
    dispatch(actions.fetchHistoryPrepaidRecharge({count:100}));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rechargeLinesUIProps.queryParams, dispatch]);
  return (
    <>
    <table className="table table table-head-custom table-vertical-center overflow-hidden">
      <thead>
        <tr>
          <th><FormattedMessage id="RECHARGE.NAME"></FormattedMessage></th>
          <th><FormattedMessage id="RECHARGE.DATE"></FormattedMessage></th>
          <th><FormattedMessage id="RECHARGE.AMOUNT"></FormattedMessage></th>
        </tr>
      </thead>
      <tbody>
        {historyPrepaidRechargeList ? (
          historyPrepaidRechargeList.map((of) => (
            <tr key={`id${of.id}`}>
              <td>{of.name}</td>
              <td>{of.date}</td>
              <td>{of.amount}</td>
            </tr>
          ))
        ) : (
          <SkeletonTheme>
            <tbody>
              <tr>
                <td>
                  <Skeleton height={20} count={3} />
                </td>
                <td>
                  <Skeleton height={20} count={3} />
                </td>
                <td>
                  <Skeleton height={20} count={3} />
                </td>
              </tr>
            </tbody>
          </SkeletonTheme>
        )}
      </tbody>

    </table>
    </>
  );
}
