/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useMemo, useLayoutEffect, useEffect, useState } from "react";
import objectPath from "object-path";
import { Link, useLocation } from "react-router-dom";
import { QuickActions } from "./components/QuickActions";
import { BreadCrumbs } from "./components/BreadCrumbs";
import {
  getBreadcrumbsAndTitle,
  useSubheader,
} from "../../_core/MetronicSubheader";
import { useHtmlClassService } from "../../_core/MetronicLayout";
import { Col, Dropdown, Nav, Navbar, NavDropdown, Row } from "react-bootstrap";
import { NavLink } from "react-router-dom/cjs/react-router-dom";
import { checkIsActive, toAbsoluteUrl } from "../../../_helpers";
import { useHistory } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import { GetLogo, GetLogoBusiness } from "../../../_helpers/GetLogo";
import Login from "../extras/Login";

export function SubHeaderBusinessScroll() {
  const uiService = useHtmlClassService();
  const location = useLocation();
  const subheader = useSubheader();
  let history = useHistory();

  const offerCategories = useSelector(
    (state) => state.profileSlice.offerCategories,
    shallowEqual
  );
  const offerPlans = useSelector(
    (state) => state.profileSlice.offerPlans,
    shallowEqual
  );
  const services = useSelector(
    (state) => state.profileSlice.services,
    shallowEqual
  );
  const navbarItems = useSelector(
    (state) => state.profileSlice.navbarItems,
    shallowEqual
  );
  const getMenuItemActive = (url) => {
    return checkIsActive(location, url) ? "menu-item-active" : "";
  };
  const layoutProps = useMemo(() => {
    return {
      config: uiService.config,
      subheaderMobileToggle: objectPath.get(
        uiService.config,
        "subheader.mobile-toggle"
      ),
      headerSelfTheme: objectPath.get(uiService.config, "header.self.theme"),
      subheaderCssClasses: uiService.getClasses("subheader", true),
      viewUserDisplay: objectPath.get(uiService.config, "extras.user.display"),
      subheaderContainerCssClasses: uiService.getClasses(
        "subheader_container",
        true
      ),
    };
  }, [uiService]);

  useLayoutEffect(() => {
    const aside = getBreadcrumbsAndTitle("kt_aside_menu", location.pathname);
    const header = getBreadcrumbsAndTitle("kt_header_menu", location.pathname);
    const breadcrumbs =
      aside && aside.breadcrumbs.length > 0
        ? aside.breadcrumbs
        : header.breadcrumbs;
    subheader.setBreadcrumbs(breadcrumbs);
    subheader.setTitle(
      aside && aside.title && aside.title.length > 0
        ? aside.title
        : header.title
    );
    // eslint-disable-next-line
  }, [location.pathname]);
  // Do not remove this useEffect, need from update title/breadcrumbs outside (from the page)
  useEffect(() => {}, [subheader]);
  const [show, setShow] = useState("");
  const showDropdown = (e) => {
    setShow(e);
  };
  const hideDropdown = (e) => {
    setShow("");
  };
  const [showServices, setShowServices] = useState(false);
  const routeChange = (path) => {
    // let path = `/korek-offers/all-offers`;
    history.push(path);
  };
  const getHeaderLogo = () => {
      let result = "logo-light.png";
      if (layoutProps.headerSelfTheme && layoutProps.headerSelfTheme !== "dark") {
          result = "logo-dark.png";
      }
      return toAbsoluteUrl(`/media/logos/${result}`);
  };

  let navBarBuss = [
    {
      id: "1",
      title: "SOHO",
      redirectionLink: "",
      sections: [
        {
          title: "Call Center System",
          redirectionLink: "/business/soho/call-center-system",
        },
        {
          title: "Postpaid Bundles",
          redirectionLink: "/business/soho/postpaid-bundles",
        },
        {
          title: "Prepaid Bundles",
          redirectionLink: "/business/soho/prepaid-bundles",
        },
        {
          title: "Data Packages",
          redirectionLink: "/business/soho/data-packages",
        },
        {
          title: "International & Roaming",
          redirectionLink: "/business/soho/international-roaming",
        },
      ],
    },
    {
      id: "2",
      title: "SME",
      redirectionLink: "",
      sections: [
        {
          title: "Call Center System",
          redirectionLink: "/business/sme/call-center-system",
        },
        {
          title: "Postpaid Bundles",
          redirectionLink: "/business/sme/postpaid-bundles",
        },
        {
          title: "prepaid-bundles",
          redirectionLink: "/business/sme/prepaid-bundles",
        },
        {
          title: "Data packages",
          redirectionLink: "/business/sme/data-packages",
        },
        {
          title: "International & Roaming",
          redirectionLink: "/business/sme/international-roaming",
        },
        {
          title: "Bulk SMS",
          redirectionLink: "/business/sme/bulk-sms",
        },
        {
          title: "Short code & Toll Free",
          redirectionLink: "/business/sme/short-code-toll-free",
        },
        {
          title: "IoT",
          redirectionLink: "/business/sme/iot",
        },
      ],
    },
    {
      id: "3",
      title: "Enterprise",
      redirectionLink: "",
      sections: [
        {
          title: "Call Center System",
          redirectionLink: "/business/enterprise/call-center-system",
        },
        {
          title: "Postpaid Bundles",
          redirectionLink: "/business/enterprise/postpaid-bundles",
        },
        {
          title: "prepaid-bundles",
          redirectionLink: "/business/enterprise/prepaid-bundles",
        },
        {
          title: "Data packages",
          redirectionLink: "/business/enterprise/data-packages",
        },
        {
          title: "International & Roaming",
          redirectionLink: "/business/enterprise/international-roaming",
        },
        {
          title: "Bulk SMS",
          redirectionLink: "/business/enterprise/bulk-sms",
        },
        {
          title: "Short code & Toll Free",
          redirectionLink: "/business/enterprise/short-code-toll-free",
        },
      ],
    },
    {
      id: "4",
      title: "Government",
      redirectionLink: "",

      sections: [
        {
          title: "Call Center System",
          redirectionLink: "/business/government/call-center-system",
        },
        {
          title: "Postpaid Bundles",
          redirectionLink: "/business/government/postpaid-bundles",
        },
        {
          title: "prepaid-bundles",
          redirectionLink: "/business/government/prepaid-bundles",
        },
        {
          title: "Data packages",
          redirectionLink: "/business/government/data-packages",
        },
        {
          title: "International & Roaming",
          redirectionLink: "/business/government/international-roaming",
        },
        {
          title: "Bulk SMS",
          redirectionLink: "/business/government/bulk-sms",
        },
        {
          title: "Short code & Toll Free",
          redirectionLink: "/business/government/short-code-toll-free",
        },
        {
          title: "IoT",
          redirectionLink: "/business/government/iot",
        },
      ],
    },
    {
      id: "5",
      title: "VIP",
      redirectionLink: "",

      sections: [
        {
          title: "Postpaid Bundles",
          redirectionLink: "/business/vip/postpaid-bundles",
        },
        {
          title: "Mobile Plans",
          redirectionLink: "/business/vip/mobile-plans",
        },
        {
          title: "Data packages",
          redirectionLink: "/business/vip/data-packages",
        },
        {
          title: "International & Roaming",
          redirectionLink: "/business/vip/international-roaming",
        },
        {
          title: "Elite Club",
          redirectionLink: "/business/vip/elite-club",
        },
      ],
    },
  ];

  return (
    <div
      id="kt_subheader"
      className={`subheader subheader-Scroll py-2 py-lg-4 subheader-business  maxWidthCont centerFixed ${layoutProps.subheaderCssClasses}`}
    >
      <div
        className={`${layoutProps.subheaderContainerCssClasses} d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap`}
      >
        <Navbar className="navbar-dark" expand="lg">
                  <Navbar.Brand href="#home">
    
                  <NavLink className="menu-link logo-subheader-mobile" to="/">
                    <img alt="logo" src={GetLogoBusiness("dark")}/>
                </NavLink>
                  </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto">
              {navBarBuss &&
                navBarBuss.map((x, i) =>
                  x.sections.length > 0 ? (
                    <NavDropdown
                      key={x.id}
                      title={x.title}
                      className="pr-5 navbar-scroll dropdown-header-animation"
                      id={`offcanvasNavbarDropdown-expand-${"xl"}`}
                      // renderMenuOnMount={true}
                      onMouseEnter={() => showDropdown(x.id)}
                      onMouseLeave={() => hideDropdown(x.id)}
                      // onClick={() => routeChange(x.redirectionLink)}
                      show={show == x.id}
                    >
                      <Row
                        style={{ alignItems: "baseline" }}
                        className="container-fluid  justify-content-start"
                      >
                        {x.sections &&
                          x.sections.map((y,i) => (
                            <Col key={i} xl={3}>
                              <NavDropdown.Item href={y.redirectionLink}>
                                <b>{y.title}</b>
                              </NavDropdown.Item>
                              {y.subsections &&
                                y.subsections.map((z) => (
                                  <NavDropdown.Item href={z.redirectionLink}>
                                    {z.title}
                                  </NavDropdown.Item>
                                ))}
                            </Col>
                          ))}
                      </Row>
                    </NavDropdown>
                  ) : (
                    <Nav.Link
                      id={`offcanvasNavbarDropdown-expand-${"xl"}`}
                      className="px-5 dropdown-header-animation"
                      href={x.redirectionLink}
                    >
                      {x.title}
                    </Nav.Link>
                  )
                )}
            </Nav>
          </Navbar.Collapse>
        </Navbar>
        <div className="scroll-login  logo-subheader-mobile">
            {layoutProps.viewUserDisplay && <Login />}</div>
      </div>
    </div>
  );
}
